<template>
  <div>
    <div class="row justify-content-center">
      <h2>Water Quality Parameters</h2>
    </div>
    <div v-for="(item, key) in imageLibraryWaterQuality" :key="key">
      <div class="row">
        <div class="col-1 my-auto justify-content-center" style="min-width: 90px; padding-left: 40px">
          <img :src="item.pic" style="max-width: 55px" :alt="'water quality parameter icon for ' + key ">
        </div>
        <div class="col-10">
          <p>
            <b class="water-quality-main">{{ item.title }}: </b><span v-html="item.explanation"></span>
          </p>
        </div>
      </div>     
    </div>
    <br>
    <div class="row justify-content-center">
        <b-button squared class="diadromous-background" @click="showDataSources">Click here for more information on <br><b>water quality parameter thresholds</b><br> used in the Ecohealth Tracking Tool.</b-button>
    </div>
  </div>
</template>

<script>
import { imageLibraryWaterQuality} from '../../lib/constants'
export default {
  data () {
    return {
        imageLibraryWaterQuality: imageLibraryWaterQuality
    }
  },
  methods: {
    showDataSources() {
      this.$emit('showDataSources');
    }
  }
}
</script>

<style scoped>
.reference {
  width: 1000px;
}

</style>
